<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="1300px">
      <v-card v-if="resolutionScreen >= 500">
        <v-card-title>
          <v-icon
            x-large
            :color="seticon(fileinboxdata.data_type)[1]"
          >{{ seticon(fileinboxdata.data_type)[0]}}</v-icon>
          &nbsp;{{fileinboxdata.filename}}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-format-list-numbered</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{fileinboxdata.document_no}}</v-list-item-title>
                  <v-list-item-subtitle>Document no</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="4">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-calendar</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{setformatdate(fileinboxdata.document_date)}}</v-list-item-title>
                  <v-list-item-subtitle>Document date</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="4">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-account-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{fileinboxdata.sender_name}}</v-list-item-title>
                  <v-list-item-subtitle>Seller name</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-file</v-icon>
                </v-list-item-icon>
                <!-- <v-list-item-icon><v-icon>{{ seticon(fileinbox.data_type)[0]}}</v-icon></v-list-item-icon> -->
                <v-list-item-content>
                  <v-list-item-title>{{fileinboxdata.document_type}}</v-list-item-title>
                  <v-list-item-subtitle>Document type</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-calendar-import</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{formatdatetime(fileinboxdata.cre_dtm)}}</v-list-item-title>
                  <v-list-item-subtitle>Receive date</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{fileinboxdata.receiver_name}}</v-list-item-title>
                  <v-list-item-subtitle>Buyer name</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>insert_drive_file</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{fileinboxdata.relation_name}}</v-list-item-title>
                  <v-list-item-subtitle>Relation Name</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-layout justify-center class="pb-3 pt-2">
            <v-flex>
             <!-- <v-btn
             depressed
              small
              fab
              color="error"
              :dark="color.darkTheme" 
             >
             <v-icon>
                 delete
               </v-icon>
             </v-btn> -->
           </v-flex>
           <v-flex class="text-right">
             <!-- แก้ -->
             <v-btn
              v-if="fileinboxdata.relation_id === '' && fileinboxdata.relation_name === '' "
              small
              color="green"
              :dark="color.darkTheme"
              @click="opencreate = true"
             >
              create relation
             </v-btn>
             <v-btn
              v-else-if="fileinboxdata.relation_id !== '' && fileinboxdata.relation_name !== '' "
             small
              color="amber"
              :dark="color.darkTheme"
              @click="opendialogrelation = true"
             >
             relation
             </v-btn>
            <v-btn
              class="mx-2"
              small
              :color="color.theme"
              disabled
              @click="dialogUpload()"
            >Upload file</v-btn>
           </v-flex>
          </v-layout>
          <div v-if="loaddataprogress === true" class="text-center">
             <v-progress-circular
                indeterminate
                size="64"
                :color="color.theme"
              >
              <span :style="colorProgress">&nbsp; &nbsp; loading</span>
              </v-progress-circular>
          </div>
          <v-data-table
            :headers="headers"
            :items="inboxdata"
            :items-per-page="5"
            :hide-default-footer="false"
            class="elevation-0"
            id="thiscontainer5"
          >
            <template  v-slot:[`header.data_type`]="{ header }">
              <span :style="headerTable">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.filename`]="{ header }">
              <span :style="headerTable">{{ header.text }}</span>
            </template>
            <template  v-slot:[`header.document_no`]="{ header }">
              <span :style="headerTable">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.document_type`]="{ header }">
              <span :style="headerTable">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.last_dtm`]="{ header }">
              <span :style="headerTable">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.file_id`]="{ header }">
              <span :style="headerTable">{{ header.text }}</span>
            </template>
            <template v-slot:item="props">
              <tr>
                <td width="5%" class="text-center">
                  <v-icon
                    large
                    :color="seticon(props.item.data_type)[1]"
                  >{{ seticon(props.item.data_type)[0]}}</v-icon>
                </td>
                <td width="20%" class="text-center">{{ props.item.filename}}</td>
                <td width="10%" class="text-center">{{ props.item.document_no }}</td>
                <td width="10%" class="text-center">{{ props.item.document_type }}</td>
                <td width="10%" class="text-center">{{formatdatetime(props.item.last_dtm)}}</td>
                <td width="5%" class="text-center">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                        <v-btn class="elevation-0" fab small v-on="on">
                          <!-- @click="downloadfile(props.item) -->
                          <v-icon>more_vert</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click=" opendialog = true, newdata=props.item ">
                          <v-list-item-icon>
                            <v-icon>
                              mdi-information
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{$t('myinboxPage.Filedetails')}}
                            </v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                      <v-list-item disabled>
                          <v-list-item-icon>
                            <v-icon>
                              mdi-download-multiple
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('dowloads')}}
                            </v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            :dark="color.darkTheme"
            @click="$emit('closedialog')"
          >{{$t('uploadfolder.buttonclose')}}</v-btn>
        </v-card-actions>
      </v-card>
      <!-- phone -->
      <v-card v-else>
        <v-card-title>Detail</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>{{ seticon(fileinboxdata.data_type)[0]}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{fileinboxdata.document_no}}</v-list-item-title>
              <v-list-item-subtitle>Document no</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-file</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{fileinboxdata.document_type}}</v-list-item-title>
              <v-list-item-subtitle>Document type</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{setformatdate(fileinboxdata.document_date)}}</v-list-item-title>
              <v-list-item-subtitle>Document date</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{fileinboxdata.sender_name}}</v-list-item-title>
              <v-list-item-subtitle>Seller name</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{fileinboxdata.receiver_name}}</v-list-item-title>
              <v-list-item-subtitle>Buyer name</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-calendar-import</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{formatdatetime(fileinboxdata.cre_dtm)}}</v-list-item-title>
              <v-list-item-subtitle>Receive date</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <br />
          <v-layout justify-center>
            <!-- ทำด้วย -->
           <!-- <v-flex>
             <v-btn
             depressed
              small
              color="error"
              :dark="color.darkTheme" 
            >Change Relation</v-btn>
           </v-flex> -->
           <v-flex class="text-right pb-1">
             <v-btn
              v-if="fileinboxdata.relation_id === '' && fileinboxdata.relation_name === '' "
              small
              color="green"
              :dark="color.darkTheme"
              @click="opencreate = true"
             >
              create relation
             </v-btn>
             <v-btn
              v-else-if="fileinboxdata.relation_id !== '' && fileinboxdata.relation_name !== '' "
             small
              color="amber"
              :dark="color.darkTheme"
              @click="opendialogrelation = true"
             >
             relation
             </v-btn>
            <v-btn
              depressed
              small
              :color="color.theme"
             disabled
              @click="dialogUpload()"
            >Upload file</v-btn>
           </v-flex>
          </v-layout>
          <v-list two-line class="mb-6 pb-6 elevation-1">
            <v-list-item v-for="item in inboxdata" :key="item.title" style="cursor: pointer;">
              <v-list-item-avatar>
                <v-icon
                  large
                  :color="seticon(item.data_type)[1]"
                  style="font-weight: 100"
                >{{ seticon(item.data_type)[0]}}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.filename"></v-list-item-title>
                <v-list-item-subtitle v-text="item.document_no"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                        <v-menu>
                          <template v-slot:activator="{ on }">
                              <v-btn class="elevation-0" fab small v-on="on">
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="opendialog = true , newdata=item">
                                <v-list-item-icon>
                                  <v-icon>
                                    mdi-information
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{$t('myinboxPage.Filedetails')}}
                                  </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item disabled>
                                <v-list-item-icon>
                                  <v-icon>
                                    mdi-download-multiple
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                  {{ $t('dowloads')}}
                                  </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" :dark="color.darkTheme" @click="$emit('closedialog')">close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialoguploadrelated
      :show="opendialogupload"
      @closedialogupload="opendialogupload = false"
      @opendialogupload="opendialogupload = true"
    ></dialoguploadrelated>
    <dialogdetailfile :show ="opendialog" :filedata ="newdata" @closedialog="opendialog = false" />
    <dialogrelation :show="opendialogrelation" @closedialog="opendialogrelation=false" :filedata="filedata" :fileinbox="fileinbox" @loaddata="loaddata()" />
    <dialogcreate :show="opencreate" @checkparameter="openarameter" @closedialog="opencreate=false" :fileinbox="fileinbox"  />
  </v-row>
</template>  
<script>
import { mapState, mapGetters } from "vuex";
//import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
//import { validationMixin } from "vuelidate";
//import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const dialoguploadrelated = () =>
  import("@/components/optional/dialog-uploadrelatedinbox");
const dialogdetailfile = ()=> 
  import("@/components/optional/dialog-detailfile");
const dialogrelation =() =>
  import("@/components/optional/dialog-relation");
const dialogcreate =() =>
  import("@/components/optional/dialog-createrelationinbox");
export default {
  props: ["show", "fileinbox","filedata"],
  components: { dialoguploadrelated, dialogdetailfile,dialogrelation,dialogcreate},
  data: function() {
    return {
      fileinboxdata:[],
      opencreate:false,
      opendialog:false,
      inboxdata: [],
      newdata: [],
      opendialogrelation:false,
      // filename: '',
      opendialogupload: false,
      loaddataprogress:true,
      headers: [
        {
          text: "#",
          align: "left",
          value: "data_type",
          sortable: false,
          width: "5%"
        },
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "filename",
          width: "10%"
        },
        {
          text: "Document no",
          align: "left",
          value: "document_no",
          sortable: false,
          width: "10%"
        },
        {
          text: "Document type",
          align: "left",
          value: "document_type",
          sortable: false,
          width: "10%"
        },
        {
          text: "Last Modified",
          align: "left",
          value: "last_dtm",
          sortable: false,
          width: "10%"
        },
        {
          text: "Option",
          align: "left",
          value: "file_id",
          sortable: false,
          width: "7%"
        }
      ]
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.loaddata();
          this.loadfile();
        }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      }
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },//
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    }
  },
  methods: {
    openarameter(values){
      console.log("value",values);
      this.fileinboxdata.relation_id = values.relation_id
      this.fileinboxdata.relation_name = values.relation_name
      this.loaddata()
      this.opencreate=false;
    },
    formatdatetime(_datetime) {
      if(_datetime === "" || _datetime === null || _datetime === undefined){
        return "-";
      }else{   
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
    loadfile(){
      this.fileinboxdata = this.fileinbox
      // console.log("fileinboxdata",this.fileinboxdata);
    },
   async loaddata() {
      let payload;
      payload = {
        inbox_id: this.fileinbox.inbox_id
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/get_relation_inbox",
          payload,{headers: { Authorization: auth.code },},
        )
        .then(response => {
          if (response.data.status === "OK") {
            this.inboxdata = response.data.data["inbox"];
            console.log("inbox", this.inboxdata);
            this.loaddataprogress = false
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    dialogUpload() {
      console.log("dialogupload");
      this.opendialogupload = true;
    },
    seticon(parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      }else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video-outline", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
       else {
        dataicon = ["mdi-file-question-outline", "black"];
      }

      return dataicon;
    },
    setformatdate(_date) {
      if(_date === '' || _date === null || _date === undefined){
        return "-";
      }else{
         let data =
        _date.substring(8, 6) +
        "/" +
        _date.substring(6, 4) +
        "/" +
        _date.substring(0, 4);
      return data || "-";
      }
     
    }
  }
};
</script>
<style>
#thiscontainer4 {
  height: calc(101vh - 450px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
#thiscontainer5 {
  height: calc(101vh - 450px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>